import React from 'react';
import Layout from '../components/Layout';
import BreadcrumbSchema from '../components/BreadcrumbSchema';
import Breadcrumbs from '../components/Breadcrumbs';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const ThanksPage = () => {
  const title = 'お問い合わせ完了｜奈良のホームページ制作なら｜インヴォルブ';
  const description = '当事務所にお問い合わせいただきありがとうございました。';
  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'お問い合わせ完了', url: 'https://www.involve-in.jp/thanks' },
  ];

  return (
    <Layout>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="noindex, follow" />
        <link rel="canonical" href="https://www.involve-in.jp/thanks/" />
        <link
          rel="icon"
          type="image/x-icon"
          href="/images/common/favicon.ico"
        />

        {/* Open Graph (OGP: Facebook, LinkedIn) */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.involve-in.jp/thanks" />
        <meta
          property="og:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />
      </Helmet>

      <section className="flex flex-col items-center justify-center min-h-[60vh] text-center px-6">
        <h2 className="text-2xl font-bold text-gray-900 md:text-3xl">
          お問い合わせありがとうございます
        </h2>
        <p className="mt-4 text-gray-600 leading-relaxed">
          お問い合わせいただき、ありがとうございます。
          <br />
          ご入力いただいた内容を確認後、担当者より原則1営業日以内に折り返しご連絡させていただきます。
          <br />
          しばらくお待ちください。
        </p>
        <Link
          to="/"
          className="mt-6 inline-block bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-6 rounded-md shadow-md transition duration-300"
        >
          トップページに戻る
        </Link>
      </section>
      <Breadcrumbs breadcrumbList={breadcrumbList} />
    </Layout>
  );
};

export default ThanksPage;
